@import "./style/variables.scss";

.app__header {
  padding: 20px;
  border-bottom: 1px solid $grey4;

  a {
    text-decoration: none;
  }
}

.app__header h1 {
  font-size: 20px;
  margin: 0;
}

.primary {
  color: $brandingRed;
}

.app__container {
  display: flex;
}

.app__leftNav {
  background-color: #fafbfd;
  flex-basis: 300px;
  padding: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.app__leftNav a {
  color: $grey2;
  font-size: 16px;
  text-decoration: none;
  margin: 15px 0;
}

.app__leftNav a:hover,
.app__leftNav a:focus {
  color: $grey1;
  text-decoration: underline;
}

.app__leftNav a.active {
  color: $grey1;
}

.app__main {
  padding: 0 20px;
  flex-grow: 1;
}

code {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 10px;
}
