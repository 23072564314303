$brandingRed: #b0676b;

$primary: #29abe2;
$primaryHover: #1d9ed5;

$secondary: #455a64;
$secondaryHover: #1c313a;

$grey1: #3f3f3f;
$grey2: #9e9e9e;
$grey3: #aeaeae;
$grey4: #e0e0e0;

$success: #33a468;
$warning: #e07c3a;
$danger: #9a0007;
