@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);
/* UNIVERSAL */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

body {
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ROOT FONT STYLES */

* {
  font-family: "Lato", Helvetica, sans-serif;
  color: #333447;
  line-height: 1.5;
}

/* TYPOGRAPHY */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/** SIMPLE GRID (C) ZACH COLE 2016 **/

/* ==== GRID SYSTEM ==== */
.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 33.75em) {
  /* 540px */
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 45em) {
  /* 720px */
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) {
  /* 960px */
  .container {
    width: 75%;
    max-width: 60rem;
  }
}

$brandingRed: #b0676b;

$primary: #29abe2;
$primaryHover: #1d9ed5;

$secondary: #455a64;
$secondaryHover: #1c313a;

$grey1: #3f3f3f;
$grey2: #9e9e9e;
$grey3: #aeaeae;
$grey4: #e0e0e0;

$success: #33a468;
$warning: #e07c3a;
$danger: #9a0007;

@mixin text-contrast($n) {
  $color-brightness: round(
    (red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000
  );
  $light-color: round(
    (red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000
  );

  @if abs($color-brightness) < ($light-color/2) {
    color: white;
  } @else {
    color: black;
  }
}





.sf-button{display:flex;justify-content:center;align-items:center;font-family:"Noto Sans", sans-serif;font-weight:500;background-color:#e0e0e0;font-size:14px;border:none;padding:10px 18px;border-radius:6px;color:#3f3f3f;box-shadow:0 2px 3px 0 #3333332b;cursor:pointer}.sf-button i{font-size:14px;color:inherit}.sf-button .startIcon{margin-right:5px}.sf-button .endIcon{margin-left:5px}.sf-button.hover,.sf-button:hover,.sf-button:focus{color:#3f3f3f;background-color:#aeaeae}.sf-button.outline{border:1px solid #29abe2;color:#29abe2;background-color:white}.sf-button.outline.hover,.sf-button.outline:hover,.sf-button.outline:focus{background-color:rgba(41,98,255,0.1)}.sf-button.text{box-shadow:none;color:#29abe2;background-color:white}.sf-button.text.hover,.sf-button.text:hover,.sf-button.text:focus{background-color:rgba(41,98,255,0.1)}.sf-button.disableShadow{box-shadow:none;background-color:#29abe2;color:white}.sf-button.disableShadow.hover,.sf-button.disableShadow:hover,.sf-button.disableShadow:focus{background-color:#1d9ed5}.sf-button:disabled{background-color:#e0e0e0;color:#9e9e9e;cursor:not-allowed}.sf-button.text:disabled{background-color:transparent}.sf-button.sm{padding:6px 16px}.sf-button.md{padding:10px 18px}.sf-button.lg{padding:13px 24px}.sf-button.primary{color:white;background-color:#29abe2}.sf-button.primary.hover,.sf-button.primary:hover,.sf-button.primary:focus{color:white;background-color:#29abe2}.sf-button.secondary{color:white;background-color:#455a64}.sf-button.secondary.hover,.sf-button.secondary:hover,.sf-button.secondary:focus{color:white;background-color:#1c313a}

.line {
  height: 1px;
  width: 100%;
  background: #d6d6d8;
  margin: 80px 0;
}

.grid-display {
  margin: 20px 0;
}

.grid-display [class^="col"] {
  background: #e0e0e0;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
}

.grid-display [class^="col"] p {
  color: #333333;
  font-size: 0.8rem;
}

pre {
  margin-top: 30px;
  overflow: auto;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  padding: 24px 0;
}

code,
.tag,
.el,
.name,
.content,
.comment {
  font-family: monospace;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #636366;
}

.el {
  color: #fe7880;
}

.name {
  color: #f9b42d;
}

.content {
  color: #75c050;
}

.comment {
  color: #b6cad2;
}

.container{width:90%;margin-left:auto;margin-right:auto}.row{position:relative;width:100%}.row [class^="col"]{float:left;margin:0.5rem 2%;min-height:0.125rem}.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12{width:96%}.col-1-sm{width:4.33%}.col-2-sm{width:12.66%}.col-3-sm{width:21%}.col-4-sm{width:29.33%}.col-5-sm{width:37.66%}.col-6-sm{width:46%}.col-7-sm{width:54.33%}.col-8-sm{width:62.66%}.col-9-sm{width:71%}.col-10-sm{width:79.33%}.col-11-sm{width:87.66%}.col-12-sm{width:96%}.row::after{content:"";display:table;clear:both}.hidden-sm{display:none}@media only screen and (min-width: 33.75em){.container{width:80%}}@media only screen and (min-width: 45em){.col-1{width:4.33%}.col-2{width:12.66%}.col-3{width:21%}.col-4{width:29.33%}.col-5{width:37.66%}.col-6{width:46%}.col-7{width:54.33%}.col-8{width:62.66%}.col-9{width:71%}.col-10{width:79.33%}.col-11{width:87.66%}.col-12{width:96%}.hidden-sm{display:block}}@media only screen and (min-width: 60em){.container{width:75%;max-width:60rem}}

.colorBlock{display:inline-block;width:160px;height:40px;margin:20px;line-height:35px;text-align:center}.colorBlock.primary{background-color:#29abe2;color:white}.colorBlock.primaryHover{background-color:#1d9ed5;color:white}.colorBlock.secondary{background-color:#455a64;color:white}.colorBlock.secondaryHover{background-color:#1c313a;color:white}.colorBlock.grey1{background-color:#3f3f3f;color:white}.colorBlock.grey2{background-color:#9e9e9e;color:black}.colorBlock.grey3{background-color:#aeaeae;color:black}.colorBlock.grey4{background-color:#e0e0e0;color:black}.colorBlock.success{background-color:#33a468;color:white}.colorBlock.warning{background-color:#e07c3a;color:black}.colorBlock.danger{background-color:#9a0007;color:white}

.sl-label{font-size:0.8rem;color:#3f3f3f}.sl-input{display:block;border-radius:6px;padding:10px;border:1px solid #aeaeae;font-size:16px}.sl-input:hover{border:1px solid #3f3f3f}.sl-input:focus{border:1px solid #29abe2;outline:none}.sl-input.success{border-color:#33a468}.sl-input.success:focus{border-color:#33a468}.sl-input.error{border-color:#9a0007}.sl-input.error:focus{border-color:#9a0007}.sl-input.warning{border-color:#e07c3a}.sl-input.warning:focus{border-color:#e07c3a}.sl-input:disabled{background-color:#e0e0e0;cursor:not-allowed}.sl-input:disabled:hover{border-color:#aeaeae}

.app__header{padding:20px;border-bottom:1px solid #e0e0e0}.app__header a{text-decoration:none}.app__header h1{font-size:20px;margin:0}.primary{color:#b0676b}.app__container{display:flex}.app__leftNav{background-color:#fafbfd;flex-basis:300px;padding:20px;font-size:16px;display:flex;flex-direction:column}.app__leftNav a{color:#9e9e9e;font-size:16px;text-decoration:none;margin:15px 0}.app__leftNav a:hover,.app__leftNav a:focus{color:#3f3f3f;text-decoration:underline}.app__leftNav a.active{color:#3f3f3f}.app__main{padding:0 20px;flex-grow:1}code{display:inline-block;margin-top:30px;margin-bottom:10px}

